import React, { useEffect } from "react";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Socials from "../components/about/socials";

import INFO from "../data/user";

import "./styles/about.css";

const About = () => {

	const codedDescription = (
		<span>

		</span>
	)

	const codedTitle = (
		<span>
			Get to know me!
		</span>
	)

	useEffect(() => {
		document.title = `About | ${INFO.main.title}`;
	}, []);

	return (
		<React.Fragment>

			<div className="page-content">
				<NavBar active="about" />
				<div className="content-wrapper">
					<div className="about-container">
						<div className="about-main">
							<div className="about-right-side">
								<div className="title about-title">
									{INFO.about.title !== "" ? INFO.about.title : codedTitle}
								</div>

								<div className="subtitle about-subtitle">
									{INFO.about.description.split('\n').map((paragraph, index) => (
										<p key={index}>{paragraph.trim()}</p>
									))}                                </div>
							</div>

							<div className="about-left-side">
								<div className="about-image-container">
									<div className="about-image-wrapper">
										<img
											src="https://pink-decent-wildcat-681.mypinata.cloud/ipfs/QmewAQ8PY5t7qXoCJ8p7VksTE6FsMkwHm6TPkhyS3js4g2"
											alt="about"
											className="about-image"
										/>
									</div>
								</div>

								<div className="about-socials">
									<Socials />
								</div>
							</div>
						</div>
						<div className="about-socials-mobile">
							<Socials />
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default About;
