// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          homepage: {
            title: "Software Developer | AI Enthusiast | Passionate about Building Scalable Solutions",
            description: "I am a final-year student specializing in Computer Engineering and Emerging Technologies. With hands-on experience in full-stack development and AI tools, I am passionate about creating innovative solutions that solve real-world problems. I enjoy working with modern technologies like React, Laravel, and Spring, and I continuously strive to expand my knowledge, particularly in AI-powered applications and mobile development."
          },
        },
      },
      fr: {
        translation: {
          homepage: {
            title: "Développeur de logiciels | Passionné par l'IA | Passionné par la construction de solutions évolutives",
            description: "Je suis un étudiant de dernière année spécialisé en ingénierie informatique et technologies émergentes. Avec une expérience pratique en développement full-stack et outils d'IA, je suis passionné par la création de solutions innovantes qui résolvent des problèmes du monde réel. J'aime travailler avec des technologies modernes comme React, Laravel et Spring, et je m'efforce continuellement d'élargir mes connaissances, notamment dans les applications alimentées par l'IA et le développement mobile."
          },
        },
      },
    },
    lng: "en", // default language
    fallbackLng: "en", // fallback language
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
