// Certificates.jsx
import React, { useEffect } from "react";
import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import INFO from "../data/user";
import './styles/certificates.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const certificates = [
	{
		src: "../AWS_Academy_Graduate___AWS_Academy_Cloud_Foundations_Badge.jpg",
		title: "AWS Cloud Foundations",
	},
	{
		src: "../AWS_Academy_Graduate___AWS_Academy_Engineering_Operations_Technicians_Badge20240926-8-2tada4_page-0001.jpg",
		title: "AWS Academy Engineering Operations Technicians",
	},
	{
		src: "../frontend_developer_react certificate_page-0001.jpg",
		title: "REACT",
	},
	{
		src: "../SQLCERTIFCATES.png",
		title: "SQL",
	},
	{
		src: "../DevOps_Essentials_Badge20241012-8-sfkjfr_page-0001.jpg",
		title: "Devops Essentials",
	},
];

const Certificates = () => {

	useEffect(() => {
		document.title = `Certificates | ${INFO.main.title}`;
	}, []);

	return (
		<React.Fragment>
			<div className="page-content">
				<NavBar active="certificates" />
				<div className="content-wrapper">
					<div className="certificates-container">
						<div className="title certificates-title">
							My Certificates
						</div>
						<div className="certificates-carousel">
							<Carousel showArrows={true} infiniteLoop={true} autoPlay={true} interval={3000}>
								{certificates.map((certificate, index) => (
									<div key={index}>
										<img src={certificate.src} alt={certificate.title} />
									</div>
								))}
							</Carousel>
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
export default Certificates;
