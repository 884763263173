const INFO = {
	main: {
		title: "Ilyass Bennane",
		name: "Ilyass Bennane",
		email: "ilyassbennane123@gmail.com",
		logo: "../headshot.png",  // You can replace this with your actual image/logo path
	},

	socials: {
		github: "https://github.com/ilyassbennane",
		linkedin: "https://www.linkedin.com/in/ilyass-bennane-739aa1250/",
	},

	homepage: {
		title: "Final-year student in Computer Engineering and Emerging Technologies",
		description: "Innovative IT student with strong expertise in software development, AI tools, and digital marketing strategies. Proficient in building both web and mobile applications and optimizing business processes.",
	},

	about :{
		title: "About Me",
		description: `I am a final-year student specializing in Computer Engineering and Emerging Technologies. With hands-on experience in full-stack development, AI tools, and mobile development, I am passionate about creating solutions that solve real-world problems. I enjoy working with modern technologies like React, Laravel, and Spring, and I continuously strive to expand my knowledge in AI-powered applications.
	  
		Throughout my academic journey, I have engaged in numerous projects that have honed my technical skills and ignited my passion for innovation. Collaborating with diverse teams, I have developed web and mobile applications that address real-world challenges, reinforcing my belief in technology's power to make a positive impact.
	  
		In addition to my coursework, I am an active participant in hackathons and tech communities, where I enjoy exploring the latest industry trends and emerging technologies. My interest in artificial intelligence has led me to delve into machine learning and data science, areas where I see immense potential for future growth.
	  
		Beyond academics and technology, I am committed to continuous personal and professional development. I believe in the importance of lifelong learning and often engage in online courses and workshops to expand my skill set. This proactive approach helps me stay adaptable in the ever-evolving tech landscape.
	  
		As I approach graduation, I am excited about the opportunity to apply my skills in a dynamic environment. I am eager to contribute to projects that not only challenge me but also have a meaningful impact on society. My goal is to join a forward-thinking organization where I can collaborate with like-minded professionals and continue to grow as a developer and innovator.`
	},
	  
    contact: {
		title: "Get In Touch",
		description: "Feel free to reach out for collaborations or just a friendly chat! You can contact me via email or connect with me on LinkedIn.",
	},

    project: {
		title: "My Projects",
		description: "Here are a few projects that I’ve worked on recently. They range from full-stack web development to AI-powered applications.",
	},

	projects: [
		{
			title: "OptiRoute",
			description:
				"An intelligent application designed for truck drivers to optimize routes and minimize travel time and distance. Built using Python and Dijkstra's algorithm.",
            logos: [
                "../openai-icon.png",
                "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
            ],
			linkText1: "GitHub",
			link1: "https://github.com/ilyassbennane",
            linkText2: "Live",
			link2: "https://www.youtube.com/watch?v=Ov6Mz4-oNWQ&feature=youtu.be"  // Example link; replace with actual if available
		},

		{
			title: "CRM Platform",
			description:
				"A CRM platform developed to centralize and manage customer data efficiently. Built using Laravel, MySQL, and TailwindCSS for businesses to streamline their operations.",
            logos: [
				"https://static-00.iconduck.com/assets.00/tailwind-css-icon-2048x1229-u8dzt4uh.png",
                "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/php/php.png",
                "https://upload.wikimedia.org/wikipedia/labs/8/8e/Mysql_logo.png",
            ],
			linkText1: "GitHub",
			link1: "https://github.com/ilyassbennane",
            linkText2: "Live",
			link2: "https://www.youtube.com/watch?v=ZVnw6u3vOA0"  // Example link; replace with actual if available
		},

		{
			title: "Incident Detection System",
			description:
				"An AI-powered system for detecting incidents in various environments using machine learning algorithms. Integrated with real-time alerts and notifications for proactive monitoring.",
            logos: [
                "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
                "https://seeklogo.com/images/T/tensorflow-logo-C69AEAC9D0-seeklogo.com.png",
            ],
			linkText1: "GitHub",
			link1: "https://github.com/ilyassbennane/",
            linkText2: "Live",
			link2: "https://www.youtube.com/watch?v=2Ep4gyoVPRw"  // Example link; replace with actual if available
		},

        {
			title: "Teacher Exchange Management",
			description:
				"A mobile app built with React Native to manage teacher exchanges across institutions, designed to streamline operations and ease communication.",
            logos: [
                "https://cdn4.iconfinder.com/data/icons/logos-3/600/React.js_logo-512.png",
                "https://greenfinchwebsitestorage.blob.core.windows.net/media/2020/03/Microsoft_.Net_Web_API.png",
            ],
			linkText1: "GitHub",
			link1: "https://github.com/ilyassbennane",
            linkText2: "Live",
			link2: "https://teacherexchange.app/"  // Example link; replace with actual if available
		},

        {
			title: "Dental Detection and Practical Work Management in Medicine",
			description:
				"A mobile application for detecting dental angles and managing practical work in the medical field. Built with Java and Spring.",
            logos: [
                "https://upload.wikimedia.org/wikipedia/en/3/30/Java_programming_language_logo.svg",
                "https://upload.wikimedia.org/wikipedia/commons/4/44/Spring_Framework_Logo_2018.svg",
            ],
			linkText1: "GitHub",
			link1: "https://github.com/ilyassbennane",
            linkText2: "Live",
			link2: "https://www.youtube.com/watch?v=B_JdjosCJRc"  // Example link; replace with actual if available
		},

        {
			title: "BI for Accident Analysis in America",
			description:
				"A BI solution designed for analyzing accident data in the U.S., leveraging Microsoft SQL Server, SSAS, SSIS, and SSRS for comprehensive reporting.",
            logos: [
                "https://gafish.fr/wp-content/uploads/2016/06/SSIS-logo.png",
                "https://www.svgrepo.com/show/303229/microsoft-sql-server-logo.svg",
            ],
			linkText1: "GitHub",
			link1: "https://github.com/ilyassbennane",
            linkText2: "Live",
			link2: "https://www.bennane.me/MiniProjetBI.pdf"  // Example link; replace with actual if available
		},
	],

};

export default INFO;
