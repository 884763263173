import { Routes, Route } from "react-router-dom";
import { useEffect } from "react"; 
import Homepage from "./pages/homepage";
import About from "./pages/about";
import Projects from "./pages/projects";
import Contact from "./pages/contact";
import Certificates from "./pages/certificates";
import Notfound from "./pages/404";
import ScrollToTop from "./components/common/ScrollToTop";
import './i18n'; // Ensure i18n is imported for initialization
import "./app.css";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/certificates" element={<Certificates/>}/>
        <Route path="/BIRapport" element={<DownloadPDF />} /> {/* New Route */}
        <Route path="*" element={<Notfound />} />
      </Routes>
      <ScrollToTop />
    </div>
  );
}

// Component to handle PDF download
function DownloadPDF() {
  // Redirects the user to the PDF file
  useEffect(() => {
    window.location.href = `https://www.bennane.me/MiniProjetBI.pdf`;
  }, []);

  return null; // No UI is needed for this route
}

export default App;
