import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

import "./styles/project.css";

const Project = (props) => {
	const { logos, title, description, linkText1, link1, linkText2, link2 } = props;

    // Function to render logos
    function renderLogos() {
        if (Array.isArray(logos)) {
            return logos.map((logo, index) => (
                <img key={index} src={logo} alt={`logo-${index}`} />
            ));
        } else {
            return <img src={logos} alt="project-logo" />;
        }
    }

	return (
		<React.Fragment>
			<div className="project">
				<div className="project-container">
					<div className="project-logos">{renderLogos()}</div>
					<div className="project-title">{title}</div>
					<div className="project-description">{description}</div>
					<div className="project-links">
						<div className="project-link-icon">
							<FontAwesomeIcon icon={faLink} />
						</div>
						<a href={link1} target="_blank" rel="noreferrer">
							<div className="project-link-text">{linkText1}</div>
						</a>
						{/* Conditionally render second link if provided */}
						{link2 && (
							<a href={link2} target="_blank" rel="noreferrer">
								<div className="project-link-text">{linkText2}</div>
							</a>
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Project;
