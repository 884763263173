import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import useTranslation for i18n
import { Switch, FormControlLabel } from "@mui/material"; // Import Material-UI Switch

import "./styles/navBar.css";

const NavBar = (props) => {
	const { active } = props;
	const { i18n } = useTranslation(); // Initialize i18n
	const [language, setLanguage] = useState("en"); // State to manage language

	// Function to toggle language
	const toggleLanguage = () => {
		const newLang = language === "en" ? "fr" : "en";
		setLanguage(newLang);
		i18n.changeLanguage(newLang); // Change the language in i18n
	};

	return (
		<React.Fragment>
			<div className="nav-container">
				<nav className="navbar">
					<div className="nav-background">
						<ul className="nav-list">
							<li className={active === "home" ? "nav-item active" : "nav-item"}>
								<Link to="/">Home</Link>
							</li>
							<li className={active === "about" ? "nav-item active" : "nav-item"}>
								<Link to="/about">About</Link>
							</li>
							<li className={active === "projects" ? "nav-item active" : "nav-item"}>
								<Link to="/projects">Projects</Link>
							</li>
							<li className={active === "certificates" ? "nav-item active" : "nav-item"}>
								<Link to="/certificates">Certificates</Link>
							</li>
							<li className={active === "contact" ? "nav-item active" : "nav-item"}>
								<Link to="/contact">Contact</Link>
							</li>
{/*							<li className="nav-item language-switch">
								<FormControlLabel
									control={
										<Switch
											checked={language === "fr"}
											onChange={toggleLanguage}
											color="primary" // Change color if needed
										/>
									}
									label={language === "en" ? "FR" : "EN"}
									labelPlacement="start" // Label before the switch
								/>
							</li>*/}
						</ul>
					</div>
				</nav>
			</div>
		</React.Fragment>
	);
};

export default NavBar;
